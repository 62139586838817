import React from 'react'
import {Layout} from '../components/layout';
import { useIntl } from 'gatsby-plugin-react-intl'
import ContactForm from "../components/contact-form";

const ContactPage = () => {
    const intl = useIntl().formatMessage;

    return (
        <Layout bodyClass="contactPage">
            <main>
                <ContactForm
                    button={intl({ id: 'contact.form.button' })}
                    email={intl({ id: 'contact.form.email' })}
                    message={intl({ id: 'contact.form.message' })}
                    name={intl({ id: 'contact.form.name' })}
                    successMessage={intl({ id: 'contact.form.successMessage' })}
                    titleForm={intl({ id: 'contact.title' })}
                    leadForm={intl({ id: 'contact.lead' })}
                />
            </main>
        </Layout>
    )

};

export default ContactPage;
